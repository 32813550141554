import React from 'react'
import "../style/myStyle.scss"

import Layout from '../components/layout'
import InfoCard from '../components/infoCard'
import SEO from '../components/seo'


const AboutPage = ({location}) => {
 return (
   <Layout location={location}>
     <SEO title="St-Martin - Le Tshirt" keywords={[`tshirt`, `home`, `bio`, `engagé`]} />
     <section className="section">
       <div className="block">Jacques est le premier t-shirt de la marque St-Martin. Il est beau, oui Jacques est beau. Provocateur. Coloré. Féministe (c’est pas un gros mot). Drôle. Engagé. Doux, si doux. Là vous vous dîtes que Jacques à de très nombreuses qualités et vous avez bien raison.</div>
       <div className="block">Jacques-à-dit « faut se lancer », donc voici St-Martin marque à l’unique produit (premier et dernier si ça ne marche pas, mais bon on va pas se porter l’oeil), qui voit le jour grâce à un quatre-mains (un duo quoi). Si ça marche bien évidemment on se rêve à sortir d’autres produits muy simpatico, mais chaque chose en son temps. Pour l’instant notre priorité c’est de livrer dans vos chaumières ce cher et tendre Jacques.</div>
       <div className="block">Saint-Martin est engagé pour un monde meilleur, car oui ça fait du bien de rêver à mieux et de se croire capable. Capable de changer un peu les choses, à son échelle, humblement et dans la bonne humeur !</div>
        <div className="block">Peace & boobs out!</div>
     </section>
     <section className="section">
       <div className="columns">
         <div className="column">
           <InfoCard title="Engagé ?" logo="../FCurie.png">
             La moitié des bénéfices va à l’Institut Curie. Par ce que le cancer, notamment celui du sein, est une saleté dont il faut se débarrasser !
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Bio ?" logo="../Coton.png">
             Un coton tout doux tout bio ! Certifié <a href="https://www.fairwear.org/" target="_blank" rel="noreferrer">Fair Wear</a>. Fais au Bangladesh.
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Fun ?" logo="../Coquillage.png">
             Décomplexé, féministe, coloré, audacieux, vitaminé ! St-Martin est une marque bonne humeur, et Jacques en est l’étendard. Boobs out!
           </InfoCard>
         </div>
       </div>
       <div className="columns">
         <div className="column">
           <InfoCard title="Traçabilité" logo="../Trace.png">
             - T-shirt coton biologique originaire du Bangladesh<br/>
             - Impression jets d’encre à Montpellier<br/>
             - Imaginé à Paris
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Fair Wear" logo="../Fair.png">
             - Certification <a href="https://www.fairwear.org/" target="_blank" rel="noreferrer">Fair Wear</a> pour un respect des conditions de travail et de l’environnement<br/>
             - <a href="https://www.fairwear.org/" target="_blank" rel="noreferrer">Découvrez les engagements</a>
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Pré-commande" logo="../Info.png">
             Vous pouvez commander jusqu'à une certaine date puis on produit pile le bon nombre de t-shirts. On évite de stocker et de sur-produire.
           </InfoCard>
         </div>
       </div>
     </section>
     <section className="section">
       <div className="images-about">
         <img src="../Vase.jpg" alt="img-vase"/>
         <img src="../Sarcasm.jpg" alt="img-sarcasm"/>
         <img src="../Duck.jpg" alt="img-duck"/>
         <img src="../Kids.jpg" alt="img-kids"/>
       </div>
       <div className="images-about">
         <img src="../Building.jpg" alt="img-building"/>
         <img src="../Grany.jpg" alt="img-grany"/>
         <img src="../Dali.jpg" alt="img-dali"/>
         <img src="../Hey.jpg" alt="img-hey"/>
       </div>
     </section>
     <section className="section">
       <div className="columns donation-card">
         <div className="column is-three-fifths donation-card__content">
           <div className="donation-card__title">Vous voulez papoter ?</div>
           <div className="donation-card__text">
             - Par email > <a href="mailto:hello@saintmartinparis.com" target="_blank">hello@saintmartinparis.com</a>.<br/>
             - Sur instagram > <a href="http://instagram.com/saintmartinparis" target="_blank">@saintmartinparis</a><br/>
             <br/>
           A très vite :)
           </div>
         </div>
         <div className="column is-two-fifths donation-card__logo">
           <img src="../Kindness.png" alt="kindness"/>
         </div>
       </div>
     </section>
   </Layout>
 )
}

export default AboutPage
